import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import {Group, Text, Label, Select}  from "../../components/Form"
import { useTranslation } from 'react-i18next'
import {CloseOutlined} from "@ant-design/icons"
import CountryCodes from "../../utils/static/CountryCodes.json"
import Countries from "../../utils/static/Countries.json"

import { Primary } from '../../components/Button'
import axios from 'axios'
import { message, Form, Input } from 'antd';
import { toggleForm } from '../../redux/actions/formActions'
import { useDispatch, useSelector } from 'react-redux'
import {useState, useRef, useEffect} from "react";
import { verifyCaptcha, clearCaptcha } from '../../redux/actions/formActions'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const apiDomain = "https://tenent-management.wittycoast-070d2551.westeurope.azurecontainerapps.io"

export default function MyModal() {
	const captchaRef = useRef(null);
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const {open, verifyCaptcha: captchaVerified} = useSelector(state => state.form)
	const required = [{ required: true, message: t("FORM.REQUIRED_MESSAGE") }]
	const countryOptions = Countries.filter((e) => e?.states?.length > 0)?.map((e) => ({ label: e.name, value: e.name }));

	const [isDomainExist, setIsDomainExist] = useState(false);
	const [domainLoading, setDomainLoading] = useState(false);

	const setIsOpen = ()=> dispatch(toggleForm())

	const handleSubmit = (values) => {
		const data = {
			...values,
			phone: `${values?.countryCode}${values?.phone}`
		}

		delete data.countryCode

		axios.post(`${apiDomain}/api/tenants/demo`, data, {
			headers: {
				'x-api-key': "dGVuYW50X21hbmFnZW1lbnQK"
			}
		})
            .then((respone) => {
                message.success(t("FORM.REQUEST_SENT_SUCCESSFULLY"));
            })
            .catch((error) => {
                message.error(t("FORM.FAILED"));
            });

		setIsOpen()
	}
	
	const handleCaptcha = (token, ekey) => {
		dispatch(verifyCaptcha(token, ekey));
	  }

	  useEffect(() => {
		return () => {
		
			if(!open) {
				if(captchaRef.current){
					captchaRef.current.resetCaptcha();
				}
				dispatch(clearCaptcha());
			}
		}
	  }, [open]) 

	  console.log(captchaVerified)

  return (
    <div className='fixed z-[9] top-0 left-0 h-screen w-screen bg-black opacity-40' style={{display:open? "block":"none"}}>
      <Transition appear show={open}>
				<Dialog as="div" className="relative z-10" onClose={() => { setIsOpen()}}>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
								<DialogPanel className="w-full max-w-md rounded-xl  p-6 bg-white relative">
									<CloseOutlined className='cursor-pointer absolute top-[1.8rem] right-5' onClick={() => setIsOpen(false)}/>
									<DialogTitle as="h3" className="text-base/7 font-medium text-black">
                    {t("HOME.REQUEST_A_DEMO")}
                  </DialogTitle>
									<div className='w-full mt-4'>
									<Group onSubmit={handleSubmit}>
										<div className='flex w-full justify-between gap-3 '>
											<div className='w-full'><Label label={t("FORM.NAME")} name='firstName' rules={required}>
												<Text placeholder={t("FORM.NAME")} />
											</Label></div>
											<div className='w-full'><Label label={t("FORM.SURNAME")} name='surnName' rules={required}>
												<Text placeholder={t("FORM.SURNAME")} />
											</Label></div>
										</div>

										<Label label={t("FORM.EMAIL")} name='email' rules={required}>
											<Text placeholder={t("FORM.EMAIL")} />
										</Label>
										<div>
											<p className="relative w-fit pr-[8px] mb-2 after:content-['*'] after:absolute after:text-[#ff4d4f] after:text-[14px] after:leading-[1] after:top-0 after:right-0 after:font-[SimSun,sans-serif]" >{t("FORM.PHONE")}</p>
											<div className='flex w-full justify-between gap-3 '>
												<div className='w-[30%]'><Label name='countryCode' rules={required}>
													<Select placeholder={t("FORM.CODE")} options={CountryCodes?.map((phone) => ({ label: phone.code + phone.dial_code, value: phone.dial_code,}))} />
												</Label></div>
												<div className='w-full'><Label name='phone' rules={required}>
													<Text placeholder={t("FORM.PHONE")} />
												</Label></div>
											</div>
										</div>
										<Label label={t("FORM.COUNTRY")} name='country' rules={required}>
											<Select placeholder={t("FORM.COUNTRY")} options={countryOptions} />
										</Label>
										<Label label={t("FORM.COMPANY")} name='companyName' rules={required}>
											<Text placeholder={t("FORM.COMPANY")} />
										</Label>

										<Form.Item label="Domain" name="domain" validateTrigger="onBlur" rules={[
											{
												required: true,
												message: 'Please input domain.'
											},
											{
												min: 3,
												message: "Please input minimum 3 characters."
											},
											{
												pattern: /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)$/,
												message: "Please input a valid subdomain (alphanumeric and hyphens, no leading/trailing hyphens).",
											},
											{
												validateTrigger: 'onBlur',
												validator: (_, value) => {
													setDomainLoading(true);
													return axios.get(`${apiDomain}/api/cloudflare/exist?domain=${value}.punica.app`, {
														headers: {
															'x-api-key': "dGVuYW50X21hbmFne93ee93lbnQK"
														}
													})
														.then(res => {
															if (res.data.data) {
																setIsDomainExist(true);
																setDomainLoading(false);
																return Promise.reject(new Error(t('FORM.DOMAIN_ALREADY_USED')));
															} else {
																setIsDomainExist(false);
																setDomainLoading(false);
																return Promise.resolve();
															}
														})
														.catch(err => Promise.reject(new Error(t('FORM.DOMAIN_ALREADY_USED'))))
												}
											}
										]}>
											<Input addonBefore="https://" addonAfter=".punica.app" placeholder="company"
												   disabled={domainLoading}/>
										</Form.Item>

										<div className='w-full flex items-center justify-center mb-[22px]'>
										<HCaptcha
											ref={captchaRef}
											sitekey="a3ae4966-e986-4650-8af6-1be8974ae666"
											onVerify={handleCaptcha}/>
										</div>

										<div className='w-full flex justify-end'><Primary disabled={isDomainExist || domainLoading || !captchaVerified} size="small" children={t("HOME.REQUEST_A_DEMO")}/> </div>
									</Group></div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
