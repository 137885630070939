import axios from 'axios'
const apiDomain = "https://tenent-management.wittycoast-070d2551.westeurope.azurecontainerapps.io"

export const toggleForm = () => dispatch => {
	dispatch({
		type: "TOGGLE_FORM",
	})
}

export const verifyCaptcha = (token, ekey) => dispatch => {
	axios
	.post(`${apiDomain}/api/auth/captcha-verify`, {token, ekey})
	.then(res => {
	  dispatch({type: "VERIFY_CAPTCHA", payload: res.data.is_success})
	})
	.catch((error) => {
		console.error(error)
	})
  }
  
  export const clearCaptcha = () => dispatch => {
	dispatch({type: "VERIFY_CAPTCHA", payload: false})
  }